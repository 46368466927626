export interface Coach {
  id: string;
  name: string;
  description: string;
}

// also src/aiApi/chat/prompt-personality.ts
export const coaches: Coach[] = [
  {
    id: "9af2e879-28ed-43b6-ab8f-a45eb6bf588d",
    name: "Leon",
    description: "Direct and results-driven coach focusing on practical solutions and actionable strategies"
  },
  {
    id: "42fd0f4b-03f2-4e96-bcd9-d64827581649",
    name: "Simon",
    description: "Empathetic and supportive coach specializing in personal development and growth"
  },
  {
    id: "02de72f4-754b-4aa1-9b72-c6a5843d97c0",
    name: "Jade",
    description: "Holistic life coach focusing on mental, emotional, and spiritual well-being"
  },
  {
    id: "71f2ccc7-8fc2-439d-9e53-9b65881884f1",
    name: "Carlos",
    description: "High-energy motivational coach with a positive, can-do attitude"
  },
  {
    id: "0cfffa6b-0074-4e0b-95e6-8df5700e60e8",
    name: "Elon",
    description: "Intense execution-focused coach who starts every session with 'What did you get done this week?'"
  },
//   {
//     id: "33149053-4fcb-457c-b2ef-9c3cfc6f9330",
//     name: "Mia",
//     description: "Data-driven life coach using evidence-based strategies and logical frameworks"
//   },
//   {
//     id: "31478929-d915-4e2d-9de5-37be89ef19e8",
//     name: "Evelyn",
//     description: "Creative coach specializing in unconventional approaches and innovative solutions"
//   },
//   {
//     id: "49b45c2c-c654-47b2-8506-fb94d25fc1d8",
//     name: "Zoe",
//     description: "Mindful and calm coach focusing on stress reduction and mindfulness practices"
//   }
]; 