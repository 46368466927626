import { useNavigate } from "react-router-dom";
import ProcessingScreen from "./ProcessingScreen";
import LoadingIndicator from "@components/loading-indicator/LoadingIndicator";
import DashboardReport from "./DashboardReport";
import { PushPermission } from "./PushPermission";
import { Initiatives } from "./BigNorthstar/Initiatives";
import AskCoach from "./AskCoach";
import { useEffect } from "react";
import { usePaywallStore } from "@/web/pages/Paywall/paywallStore";
import { BigNorthStarTitle } from "./BigNorthstar/BigNorthStarTitle";
import { useAuthContext } from "@/providers/AuthProvider";
import { useFetchUserById } from "@/queries/user";
import { useFetchPromptAnswerByGoalId } from "@/queries/promptAnswers";

export function Dashboard() {
  const navigate = useNavigate();
  const { user: currentUser } = useAuthContext();
  const { data: user } = useFetchUserById(currentUser?.uid || "");
  const {
    data: promptAnswer,
    isLoading,
    error: promptAnswerError,
  } = useFetchPromptAnswerByGoalId(user?.bigGoalId);
  const { assessmentData, report } = usePaywallStore();

  // If user has no big goal, meaning they are not onboarded, navigate to onboarding
  useEffect(() => {
    if (user && !user.bigGoalId) {
      // If there's assessment data in paywall store, complete it
      if (assessmentData && !report) {
        navigate("/start/step5");
        return;
      }

      // else it's a new user that's not come from paywall, navigate to onboarding
      navigate("/onboarding");
    }
  }, [user, navigate, assessmentData, report]);

  if (isLoading) {
    return <LoadingIndicator />;
  }

  if (promptAnswerError) {
    return (
      <div className="flex flex-col items-center justify-center min-h-[50vh] p-4 max-w-xl mx-auto">
        <h2 className="text-xl font-semibold text-primary mb-2">
          Oops! Something is outdated.
        </h2>
        <p className="text-gray-600 text-center mb-4">
          It seems you have an older version of your 360 Report that needs to be
          updated. Please contact our support team and we&apos;ll help fix this
          right away.
        </p>
        <div className="flex flex-col items-center gap-3">
          <button
            onClick={() =>
              (window.location.href =
                "mailto:support@lifehack.org?subject=Dashboard%20Error%20-%20Old%20360%20Report")
            }
            className="px-4 py-2 bg-primary text-white rounded-lg hover:bg-primary/80 transition-colors"
          >
            Contact Support
          </button>
        </div>
      </div>
    );
  }

  if (!promptAnswer) {
    return null;
  }

  if (
    promptAnswer.isWaitingHumanCoach === undefined ||
    promptAnswer.isWaitingHumanCoach === true
  ) {
    return <ProcessingScreen />;
  }

  return (
    <div className="p-2 md:p-6">
      <PushPermission />
      <BigNorthStarTitle />
      <AskCoach goalId={promptAnswer.goalId} />
      <DashboardReport prompt={promptAnswer} />
      <Initiatives />
    </div>
  );
}
