import { Avatar, AvatarFallback } from "@shadcn/avatar";
import { cn } from "@shared/lib/utils";
// import { Sparkles, UserCircle2 } from "lucide-react";
import Markdown from "markdown-to-jsx";
import { Message as MessageType } from "ai";
import { AiToolExecutionInfo } from "./AiToolExecutionInfo";

function cleanMessage(message: string): string {
  return message.replace(/【\d+:\d+†source】/g, "");
}

export interface ExtendedMessage extends MessageType {
  coachName?: string;
}

interface MessageProps {
  message: ExtendedMessage;
  coachName: string;
}

export function Message({ message, coachName }: MessageProps) {
  const { role = "assistant", content, toolInvocations } = message;

  return (
    <div
      className={cn(
        "flex flex-col gap-2 p-4 whitespace-pre-wrap",
        role !== "user" ? "items-start" : "items-end"
      )}
    >
      <div className="flex gap-3">
        {!toolInvocations && role !== "user" && (
          <Avatar className="flex justify-center items-center my-3">
            <AvatarFallback className="bg-brand-primary/10 text-brand-primary">
              {coachName[0]}
            </AvatarFallback>
          </Avatar>
        )}
        <span
          className={cn(
            "p-3 rounded-md",
            role !== "user" ? "" : "bg-slate-200"
          )}
        >
          <Markdown className="md-container">{cleanMessage(content)}</Markdown>
          {toolInvocations && <AiToolExecutionInfo executions={toolInvocations} />}
        </span>
      </div>
    </div>
  );
}
